import React from "react";

const PricingCard = ({ title, features = [], price }) => {
  return (
    <div
      className="flex-col bg-white rounded-md shadow-md max-w-2xl w-full lg:mx-4 mb-8 lg:mb-0"
      style={{ minWidth: 300 }}
    >
      <div className="flex-col bg-violet-500 text-white rounded-t-md rounded-b-lg shadow-md p-8">
        <p className="text-3xl mb-4 text-center">{title}</p>
        <p className="text-6xl font-bold mb-4 text-center">
          ${price}
          {price > 0 && <span className="text-xl">起</span>}
        </p>
      </div>
      <div className="flex justify-center items-center p-8">
        <div className="inline-flex flex-col">
          {features.map((f) => (
            <span className="inline-flex mb-2 text-lg" key={f.title}>
              {f.icon}
              {f.title}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
