import React from "react";
import FeatureCard from "../../components/card/featureCard";
import Section from "../../components/section";
import bgD4 from "../../images/background-deco-4.svg";

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import PricingCard from "../../components/card/pricingCard";

const Pricing = () => {
  return (
    <Section backgrounds={{ lg: [bgD4], sm: [bgD4] }} bgFixed id="pricing">
      <div className="flex flex-col justify-center items-center w-full">
        <p className="text-white text-6xl mb-4 text-center">定價</p>
        <p className="text-white text-xl mb-4 text-center">
          一次付費，永久使用
        </p>
        <div className="flex flex-wrap lg:flex-nowrap">
          <PricingCard
            title="免費試用版"
            price={0}
            features={[
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "首300字免費",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "限時練習模式",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "書籤練習",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "倉頡教學",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "倉頡字典",
              },
              {
                icon: (
                  <AiFillCloseCircle className="text-3xl text-red-400 mr-2" />
                ),
                title: "有廣告",
              },
            ]}
          />
          <PricingCard
            title="付費完整版"
            price={50}
            features={[
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "3000+個常用字可供選擇",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "限時練習模式",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "210個部首分類練習",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "書籤練習",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "文章練習",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "倉頡教學",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "倉頡字典",
              },
              {
                icon: (
                  <AiFillCheckCircle className="text-3xl text-green-400 mr-2" />
                ),
                title: "無廣告",
              },
            ]}
          />
        </div>
      </div>
    </Section>
  );
};

export default Pricing;
